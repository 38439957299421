import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

// Types
export type BreakdownReason = { reason: string; count: number };
export type ReturnData = {
  return_id: number;
  unique_key: number;
  return_date: string;
  return_delivered: string;
  order_number: string;
  sku_list: string;
  reason: string;
  tracking_number: string;
  thumbnail: string;
  product_title: string;
  order_value: number;
  order_quantity: number;
  fbv_enabled: boolean;
  fbv_warehouse: string;
  penalty_amount: number;
};
export type Pagination = {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_pages: number;
  total_count: number;
};
export type Summary = {
  total_returns: number;
  total_penalty_amount: number;
  breakdown_by_reason: BreakdownReason[];
};
export type Filters = {
  startDate?: Date;
  endDate?: Date;
  orderNumber?: string;
  sku?: string;
  reason?: string;
  fbvEnabled?: boolean;
  fbvWarehouse?: string;
};
export type ReturnPenaltyState = {
  summary: Summary | null;
  returns_data: ReturnData[];
  pagination: Pagination | null;
  sortedColumn: keyof ReturnData | null;
  filters: Filters;
  loading: boolean;
  error: string | null;
};

// Initial State
const initialState: ReturnPenaltyState = {
  summary: null,
  returns_data: [],
  pagination: null,
  sortedColumn: null,
  filters: {},
  loading: false,
  error: null,
};

// Helper: Build Request URL
const buildQueryParams = (params: Record<string, any>): string => {
  return Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");
};

const buildRequestUrl = (requestData: any): string => {
  const queryParams = buildQueryParams({
    "q[requested_at_gteq]": requestData.startDateFilter,
    "q[requested_at_lteq]": requestData.endDateFilter,
    "q[delivered_at_gteq]": requestData.returnsReportReceivedAtStartDateFilter,
    "q[delivered_at_lteq]": requestData.returnsReportReceivedAtEndDateFilter,
    page: requestData.page + 1,
    per_page: requestData.rowsPerPage,
  });
  return `forge/penalties/return_penalty?${queryParams}`;
};


const buildRequestUrlCsv = (requestData: any): string => {
  const queryParams = buildQueryParams({
    "q[requested_at_gteq]": requestData.startDateFilter,
    "q[requested_at_lteq]": requestData.endDateFilter,
    "q[delivered_at_gteq]": requestData.returnsReportReceivedAtStartDateFilter,
    "q[delivered_at_lteq]": requestData.returnsReportReceivedAtEndDateFilter,
    page: requestData.page + 1,
    per_page: requestData.rowsPerPage,
  });
  return `forge/penalties/return_penalty.csv?${queryParams}`;
};



export const fetchReturnPenaltiesCsv = createAsyncThunk(
  "returnPenalty/fetchDownloadReport",
  async (
    { headers = {}, ...requestData }: { headers?: Record<string, string>; [key: string]: any },
    { rejectWithValue }
  ) => {
    const config: AxiosRequestConfig = { headers };
    const url = buildRequestUrlCsv(requestData);

    try {
      const { data } = await axiosInstance.get(url, config);
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.status === 401
            ? "Unauthorized! Login again."
            : error.response?.data || "An unexpected error occurred."
        );
      }
      throw error;
    }
  }
);

// Async Thunk
export const fetchReturnPenalties = createAsyncThunk(
  "returnPenalty/fetch",
  async (
    { headers = {}, ...requestData }: { headers?: Record<string, string>; [key: string]: any },
    { rejectWithValue }
  ) => {
    const config: AxiosRequestConfig = { headers };
    const url = buildRequestUrl(requestData);

    try {
      const { data } = await axiosInstance.get(url, config);
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.status === 401
            ? "Unauthorized! Login again."
            : error.response?.data || "An unexpected error occurred."
        );
      }
      throw error;
    }
  }
);

// Slice
const returnPenaltySlice = createSlice({
  name: "returnPenalty",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReturnPenalties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReturnPenalties.fulfilled, (state, action: PayloadAction<{data: ReturnPenaltyState}>) => {
        const { summary, returns_data, pagination } = action.payload.data;
        state.summary = summary ;
        state.returns_data = returns_data;
        state.pagination = pagination;
        state.loading = false;
      })
      .addCase(fetchReturnPenalties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export actions and reducer
export const { setFilter, resetFilters } = returnPenaltySlice.actions;
export default returnPenaltySlice.reducer;
