import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance"; // Replace with your axios instance
import { AxiosError, AxiosRequestConfig } from "axios";

// Define the type for the state
export type ProductVariant = {
    id: number;
    sku: string;
    fbv_enabled: boolean;
    title: string;
    variant_title: string | null;
    image_src: string | null;
    oos_days: number;
    oos_days_last_month: number;
    out_of_stock_date: string | null;
}

export type Penalty = {
    penalty_amount: number;
    penalty_reason: string | null;
}

export type Summary = {
    total_products: number;
    oos_percentage: number;
    total_oos_days_last_month: number;
    total_stock_days_last_month: number;
    penalty: Penalty;
}

export type OosPenaltyState = {
    summary: Summary | null;
    product_variants: ProductVariant[];
    loading: boolean;
    error: string | null;
}

// Initial state
const initialState: OosPenaltyState = {
    summary: null,
    product_variants: [],
    loading: false,
    error: null,
};

export const fetchOosPenaltiesCsv = createAsyncThunk(
  "oosPenalty/fetchCsv",
  async (
    { headers = {}, month, year }: { headers?: Record<string, string>; month: number; year: number },
    { rejectWithValue }
  ) => {
    const config: AxiosRequestConfig = { headers };
    const url = `forge/penalties/oos_penalty.csv?month=${month}&year=${year}`;

    try {
      const response = await axiosInstance.get(url, config);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.status === 401
            ? "Unauthorized! Login again."
            : error.response?.data || "An unexpected error occurred."
        );
      }
      throw error;
    }
  }
);

export const fetchOosPenalties = createAsyncThunk(
  "oosPenalty/fetch",
  async (
    { headers = {}, month, year }: { headers?: Record<string, string>; month: number; year: number },
    { rejectWithValue }
  ) => {
    const config: AxiosRequestConfig = { headers };
    const url = `forge/penalties/oos_penalty?month=${month}&year=${year}`;

    try {
      const { data } = await axiosInstance.get(url, config);
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.status === 401
            ? "Unauthorized! Login again."
            : error.response?.data || "An unexpected error occurred."
        );
      }
      throw error;
    }
  }
);

// Create slice
const oosPenaltySlice = createSlice({
  name: "oosPenalty",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOosPenalties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOosPenalties.fulfilled, (state, action: PayloadAction<{data: OosPenaltyState}>) => {
        const { product_variants, summary } = action.payload.data;
        state.product_variants = product_variants;
        state.summary = summary;
        state.loading = false;
      })
      .addCase(fetchOosPenalties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export reducer
export default oosPenaltySlice.reducer;
