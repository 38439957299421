import React, { useEffect, useState } from 'react';
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { Box, FormControl, TextField, RadioGroup, FormControlLabel, Radio, useTheme, useMediaQuery, Typography } from "@mui/material";
import { styled } from '@mui/system';
import styles from "./OrderCancelModal.module.scss";
import ColorButton from 'components/atoms/ColorButton/ColorButton';
import ModalBox from 'components/atoms/ModalBox/ModalBox';

type OrderCancellationModalPropType = {
  open: boolean;
  onClose: () => void;
  handleOrderCancel: (reason: Object) => Promise<void>;
  setOrderIdToCancel: (orderId: number | string) => void;
}

const Input = styled('input')({
  display: 'none',
});

const getHelpReasons = [
  {
    value: 'out_of_stock',
    label: 'Out of Stock',
  },
  {
    value: 'others',
    label: 'Others',
  },
];

type FormStateType = {
  reason: string;
  description: string;
};

const initialFormState: FormStateType = {
  reason: '',
  description: '',
};

type FormContentPropType = {
  isMobile: boolean;
  formData: FormStateType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  handleSubmit: () => void;
};

const FormContent: React.FC<FormContentPropType> = (props: FormContentPropType) => {
  const { formData, handleChange, onClose, handleSubmit } = props;
  const [error, setError] = useState('');

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const wordCount = value.trim().split(/\s+/).length;
    if (wordCount > 25) {
      setError('Description cannot exceed 25 words.');
      return;
    } else {
      setError('');
    }
    handleChange(event);
  };

  return (
    <div className={props.isMobile ? styles['swipeable-mobile-drawer'] : styles['swipeable-mobile-drawer']}>
      <div className={styles['get-help-header']}>
          Cancellation Reason
        </div>
      <Box component="form" className={styles['gethelp-form']}>
        <FormControl fullWidth>
          <RadioGroup row aria-label="order-cancellation-reason" name="reason">
            {getHelpReasons.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                onChange={(event, checked) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
                name="reason"
              />
            ))}
          </RadioGroup>
        </FormControl>

        {formData.reason === 'others' && (
          <>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description*"
            name="description"
            onChange={handleDescriptionChange}
            value={formData.description}
            error={!!error}
            helperText={error}
          />
          <Typography variant="caption" color="textSecondary">
            Max Limit: 25 Words
          </Typography>
          </>
        )}

        <div className={styles["image-upload"]}>
          {/* Image upload section remains the same */}
        </div>

        <div className={styles['get-help-footer']}>
          <ColorButton bgColor="#FFF" className={styles['discard-button']} variant="outlined" onClick={onClose}>
            Discard
          </ColorButton>
          <ColorButton 
            bgColor="#3361FF" 
            bgHoverColor="#2E58E8" 
            className={styles['submit-button']}
            onClick={handleSubmit}
            disabled={formData.reason.length <= 1 || (formData.reason === 'others' && (formData.description.length <= 1 || formData.description.split(/\s+/).length > 25))}
          >
            Save
          </ColorButton>
        </div>
      </Box>
    </div>
  );
}

const OrderCancellationModal = ({ open, onClose, handleOrderCancel }: OrderCancellationModalPropType) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormStateType>(initialFormState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if(open){
      setFormData(initialFormState);
    }
  }, [open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement & { checked: boolean }>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // Implement your cancellation logic here
    console.log("Order cancellation submitted:", formData);
    handleOrderCancel(formData);
    onClose();
  };


  return (
    <ModalBox open={open} onClose={onClose} className={styles['get-help-modal']}>
      <FormContent 
        isMobile={isMobile}
        formData={formData} 
        handleChange={handleChange}
        onClose={onClose}
        handleSubmit={handleSubmit}
      />
    </ModalBox>
  );
}

export default OrderCancellationModal;
