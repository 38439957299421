import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../store";
import styles from "./PenaltyReports.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { resetAuth } from "../../slices/authSlice";
import Loader from "components/atoms/Loader/Loader";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  MIXPANEL_EVENT_TYPES,
  triggerMixpanelEvent,
} from "../../hooks/mixpanel_hook";
import {
  DateRangeType,
  DEFAULT_BLACK_COLOR,
  ROUTE_REPORT_MAP,
  ROUTES,
} from "../../constants";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, returnsPenaltyColumns } from "./ReturnPenalty.constant";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import {
  setReturnsPenaltyReceivedAtStartDateFilter,
  setReturnsPenaltyReceivedAtEndDateFilter,
  setReturnsPenaltyReturnedAtStartDateFilter,
  setReturnsPenaltyReturnedAtEndDateFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { downloadFile } from "../../helpers/utils";
import DateRangeSelector, {
  DateRangeLabel,
} from "components/molecules/DateRangeSelector/DateRangeSelector";
import {
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Button,
  Fab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchReturnPenalties,
  ReturnData,
  BreakdownReason,
  fetchReturnPenaltiesCsv,
} from "slices/returnsPenaltySlice";
import MonthSelector from "components/molecules/MonthSelector/MonthSelector";

const ReturnPenalty: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const PAGE_TITLE = "Return Penalty Report";

  // Redux state
  const { returns_data, pagination, summary } = useAppSelector(
    (state: RootState) => state.returnPenalty
  );
  const {
    returnsPenaltyReturnedAtStartDateFilter,
    returnsPenaltyReturnedAtEndDateFilter,
    returnsPenaltyReceivedAtStartDateFilter,
    returnsPenaltyReceivedAtEndDateFilter,
  } = useAppSelector((state: RootState) => state.filters);

  const selectedMonths = useAppSelector(
    (state: RootState) => state.filters.oosPenaltyReportMonthFilter
  );

  // Local states
  const [loaderActive, setLoaderActive] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [showReturnDate, setShowReturnDate] = useState(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [trackingProgress, setTrackingProgress] = useState<
    { label: string; value: string }[]
  >([]);
  const [showProgressDrawer, setShowProgressDrawer] = useState(false);
  const [showMonthOptions, setShowMonthOptions] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showRaisedDate, setShowRaisedDate] = useState<boolean>(true);

  // Handlers
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setFilterApplied(true);
  };

  const handleDisabledMonths = useCallback((month: number, year: number) => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    return (
      year < 2024 ||
      year > currentYear ||
      (year === currentYear && month > currentMonth)
    );
  }, []);

  const handleMonthSelection = useCallback(
    (month: number, year: number) => {
      const startDate = new Date(year, month - 1, 1);
      const endDate = new Date(year, month, 0);
      dispatch(setReturnsPenaltyReturnedAtStartDateFilter(startDate));
      dispatch(setReturnsPenaltyReturnedAtEndDateFilter(endDate));
      setFilterApplied(true);
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear, currentMonth - 1, 1);
    const endDate = new Date(currentYear, currentMonth, 0);

    dispatch(setReturnsPenaltyReturnedAtStartDateFilter(startDate));
    dispatch(setReturnsPenaltyReturnedAtEndDateFilter(endDate));

    setFilterApplied(true);
  }, [dispatch]);

  const handleSort = useCallback((column: keyof ReturnData) => {
    console.log("Sorting by column:", column);
  }, []);

  const handleStartEndDateSelect = useCallback(
    (ranges: DateRangeType) => {
      const { startDate, endDate } = ranges;
      if (showReturnDate) {
        dispatch(setReturnsPenaltyReturnedAtStartDateFilter(startDate as Date));
        dispatch(setReturnsPenaltyReturnedAtEndDateFilter(endDate as Date));
      } else {
        dispatch(setReturnsPenaltyReceivedAtStartDateFilter(startDate as Date));
        dispatch(setReturnsPenaltyReceivedAtEndDateFilter(endDate as Date));
      }
      setFilterApplied(true);
    },
    [showReturnDate, dispatch]
  );

  const showTrackingProgress = useCallback(
    (steps: { label: string; value: string }[]) => {
      setTrackingProgress(steps);
      setShowProgressDrawer(true);
    },
    []
  );

  const fetchData = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      dispatch(resetAuth());
      dispatch(resetFilter());
      return;
    }

    const token = JSON.parse(userInfo).token;
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const result = await dispatch(
        fetchReturnPenalties({
          startDateFilter: returnsPenaltyReturnedAtStartDateFilter,
          endDateFilter: returnsPenaltyReturnedAtEndDateFilter,
          returnsPenaltyReceivedAtStartDateFilter,
          returnsPenaltyReceivedAtEndDateFilter,
          page,
          rowsPerPage,
          headers,
        })
      ).unwrap();
      console.log("Fetched data successfully:", result);
    } catch (error) {
      console.error("Error fetching return penalties:", error);
    } finally {
      setFilterApplied(false);
      setLoaderActive(false);
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    returnsPenaltyReturnedAtStartDateFilter,
    returnsPenaltyReturnedAtEndDateFilter,
    returnsPenaltyReceivedAtStartDateFilter,
    returnsPenaltyReceivedAtEndDateFilter,
  ]);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById("download-button");
    if (button) {
      if (!fab) button.innerHTML = "Downloading...";
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () =>
          dispatch(
            fetchReturnPenaltiesCsv({
              startDateFilter: returnsPenaltyReturnedAtStartDateFilter,
              endDateFilter: returnsPenaltyReturnedAtEndDateFilter,
              returnsPenaltyReceivedAtStartDateFilter,
              returnsPenaltyReceivedAtEndDateFilter,
              page,
              rowsPerPage,
              headers: headers,
            })
          );
        const success = await downloadFile({
          downloadApi,
          fileName: "report.csv",
          fileType: "text/csv",
        });
        if (success) {
          triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.DOWNLOAD, {
            report_name: PAGE_TITLE,
            download_type: "Report",
          });
        }
      } else {
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if (!fab) button.innerHTML = "Download";
    }
  };

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [filterApplied, fetchData]);

  const handleTabChange = useCallback(
    (newValue: string) => {
      triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.REPORT_VIEWED, {
        report_name: ROUTE_REPORT_MAP[newValue],
        source: "Navigation",
      });
      navigate(newValue);
    },
    [navigate]
  );

  function getMonthAbbreviation(monthNumber: number) {
    const date = new Date(0, monthNumber - 1); // Month is zero-indexed in Date
    return date.toLocaleString("en-US", { month: "short" });
  }
  let label = `${getMonthAbbreviation(selectedMonths[0].month)} ${
    selectedMonths[0].year
  }`;

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <Box className={styles.filterAndDownloadWrapper}>

        <MonthSelector
          onClick={handleMonthSelection}
          showCalendar={showMonthOptions}
          setShowCalendar={setShowMonthOptions}
          disabledMonths={handleDisabledMonths}
          selectedMonths={selectedMonths}
          onClear={handleClear}
          onSubmit={() => {
            setFilterApplied(true);
            setShowMonthOptions(false);
          }}
        >
          <DateRangeLabel
            noDateLabel={label}
            onClick={() => {
              setShowMonthOptions(true);
            }}
          />
        </MonthSelector>
          {!isMobile ? (
            <Button
              id="download-button"
              className={styles.downloadBtn}
              onClick={() => downloadReport()}
            >
              Download
            </Button>
          ) : (
            <Fab
              className={styles.downloadFAB}
              onClick={() => downloadReport(true)}
              size="medium"
              id="download-button"
            >
              <FileDownloadOutlinedIcon fontSize="small" />
            </Fab>
          )}
          <Fab
            className={styles.downloadFAB}
            onClick={() => downloadReport(true)}
            size="medium"
            id="download-button"
          >
            <FileDownloadOutlinedIcon fontSize="small" />
          </Fab>
        </Box>
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={(event, newValue) => handleTabChange(newValue)}
          TabIndicatorProps={{ sx: { backgroundColor: "#4F76FE" } }}
        >
          {[
            { label: "Out of Stock", route: ROUTES.OOS_PENALTY },
            { label: "Returns", route: ROUTES.RETURN_PENALTY },
            {
              label: "Order cancellation",
              route: ROUTES.ORDER_CANCELLATION_PENALTY,
            },
          ].map(({ label, route }) => (
            <Tab
              key={route}
              label={label}
              value={route}
              style={{
                textTransform: "none",
                fontWeight: 400,
                color:
                  window.location.pathname === route
                    ? "#3361FF"
                    : DEFAULT_BLACK_COLOR,
              }}
            />
          ))}
        </Tabs>
      </Box>
      {!loaderActive && (
        <Box className={styles.paymentSummaryWrapper}>
          <DataRow
            className={styles["payment-summary"]}
            separator
            children={[
              ...(summary?.breakdown_by_reason?.map(
                (item: BreakdownReason, index: number) => (
                  <DataCol
                    key={`reason-${index}`}
                    colClass={styles["individual-payment"]}
                    labelClass={styles["payment-cycle"]}
                    valueClass={styles["payment-value"]}
                    label={item.reason || "N/A"}
                    value={item.count || 0}
                  />
                )
              ) || []),
              <DataCol
                key="total-penalty"
                colClass={styles["individual-payment"]}
                labelClass={styles["payment-cycle"]}
                valueClass={`${styles["payment-value"]} ${styles["red-pill"]}`}
                label="Total Penalty Amount"
                value={summary?.total_penalty_amount || 0}
              />,
            ]}
          />
        </Box>
      )}
      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ReturnData>
          columns={returnsPenaltyColumns({ showTrackingProgress, isMobile })}
          data={returns_data}
          currentPage={page}
          rowPerPage={rowsPerPage}
          totalCount={pagination?.total_count || 0}
          sortedColumn={null}
          handleSort={handleSort}
          onRowPerPageChange={handleChangeRowsPerPage}
          sortDirection="desc"
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 100]}
          isDataPaginated
          fixedColumn={isMobile ? fixedColumn : undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => setShowDateRangeFilter(false)}
        startDate={
          showReturnDate
            ? returnsPenaltyReturnedAtStartDateFilter
            : returnsPenaltyReceivedAtStartDateFilter
        }
        endDate={
          showReturnDate
            ? returnsPenaltyReturnedAtEndDateFilter
            : returnsPenaltyReceivedAtEndDateFilter
        }
        updateDateRange={handleStartEndDateSelect}
        onOpen={() => setShowDateRangeFilter(true)}
      />
    </Box>
  );
};

export default ReturnPenalty;
